export class MapControl {
  vm = null;
  name = '';
  map = null;
  divId = null;
  baselayers = [];
  

  init() {
    // this.baselayers = [
    //   new ol.layer.Tile({
    //     source: new ol.source.XYZ({
    //     url: "https://webst01.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z}",
    //     maxZoom: 19
    //   }),
    // })
    // ];
    this.baselayers = this.CreateTiandituVecCLayers();
    this.map = new ol.Map({
      controls: ol.control.defaults({
        attribution: false,
        zoom: false
      }),
      layers: this.baselayers,
      target: this.divId,
      view: new ol.View({
        projection: "EPSG:4326",
        center: [116.17432103787424, 40.07046871201],
        zoom: 13,
      }),
    });
    this.addHaiDXzqhLayer();
    this.addTile();
    this.addWaterworksLayer();
    this.addWaterstationLayer();
    this.addDrainLayer();
    this.addwaterqualityStationLayer();
    this.addvideoMonitorLayer();
    this.addpumpStationLayer();
    this.addwaterGateLayer();
  }


  CuiHuLayer = null;
  DaoXiangHuLayer = null;
  ShangZhuanLayer = null;
  YongFengLayer = null;
  YuQuanShanLayer = null;
  YuanMingYuanLayer = null;

  waterworksLayer = null;     // 水厂  再生水厂
  waterstationLayer = null;   // 水位监测站 水位站
  drainLayer = null;          // 排水口
  waterqualityStationLayer = null;   // 水质站
  videoMonitorLayer = null;         // 视频监控点
  pumpStationLayer = null;           // 水泵
  waterGateLayer = null;             // 水闸

  HaiDXzqhLayer = null;   // 海淀行政区域

  addWaterworksLayer() {
    this.waterworksLayer = new ol.layer.Tile({
      visible: false,
      source: new ol.source.TileWMS({
        url: 'http://39.101.128.113:8090/geoserver/provide_water/wms',
        params: {'FORMAT': 'image/png', 
                  'VERSION': '1.1.1',
                  tiled: true,
              "STYLES": '',
              "LAYERS": 'provide_water:waterworks',
              "exceptions": 'application/vnd.ogc.se_inimage',
            tilesOrigin: 116.1650562286377 + "," + 40.065003633499146
        }
      })
    });
    this.map.addLayer(this.waterworksLayer);
  }


  addWaterstationLayer() {
    this.waterstationLayer = new ol.layer.Tile({
      visible: false,
      source: new ol.source.TileWMS({
        url: 'http://39.101.128.113:8090/geoserver/provide_water/wms',
        params: {'FORMAT': 'image/png', 
                  'VERSION': '1.1.1',
                  tiled: true,
              "STYLES": '',
              "LAYERS": 'provide_water:waterstation',
              "exceptions": 'application/vnd.ogc.se_inimage',
            tilesOrigin: 116.081629 + "," + 39.94498
        }
      })
    });
    this.map.addLayer(this.waterstationLayer);
  }

  addDrainLayer() {
    this.drainLayer = new ol.layer.Tile({
      visible: false,
      source: new ol.source.TileWMS({
        url: 'http://39.101.128.113:8090/geoserver/provide_water/wms',
        params: {'FORMAT': 'image/png', 
                  'VERSION': '1.1.1',
                  tiled: true,
              "STYLES": '',
              "LAYERS": 'provide_water:drain',
              "exceptions": 'application/vnd.ogc.se_inimage',
            tilesOrigin: 116.11486673355103 + "," + 40.03909349441528
        }
      })
    });
    this.map.addLayer(this.drainLayer);
  }

  addwaterqualityStationLayer() {
    this.waterqualityStationLayer =  new ol.layer.Tile({
      visible: false,
      source: new ol.source.TileWMS({
        url: 'http://39.101.128.113:8090/geoserver/provide_water/wms',
        params: {'FORMAT': 'image/png', 
                  'VERSION': '1.1.1',
                  tiled: true,
              "STYLES": '',
              "LAYERS": 'provide_water:waterQualityStation',
              "exceptions": 'application/vnd.ogc.se_inimage',
            tilesOrigin: 116.1295611857 + "," + 39.8966793
        }
      })
    });
    this.map.addLayer(this.waterqualityStationLayer);
  }

  addvideoMonitorLayer() {
    this.videoMonitorLayer = new ol.layer.Tile({
      visible: false,
      source: new ol.source.TileWMS({
        url: 'http://39.101.128.113:8090/geoserver/provide_water/wms',
        params: {'FORMAT': 'image/png', 
                  'VERSION': '1.1.1',
                  tiled: true,
              "STYLES": '',
              "LAYERS": 'provide_water:videoMonitor',
              "exceptions": 'application/vnd.ogc.se_inimage',
            tilesOrigin: 116.03 + "," + 39.74
        }
      })
    });
    this.map.addLayer(this.videoMonitorLayer);
  }

  addpumpStationLayer() {
    this.pumpStationLayer = new ol.layer.Tile({
      visible: false,
      source: new ol.source.TileWMS({
        url: 'http://39.101.128.113:8090/geoserver/provide_water/wms',
        params: {'FORMAT': 'image/png', 
                  'VERSION': '1.1.1',
                  tiled: true,
              "STYLES": '',
              "LAYERS": 'provide_water:pumpStation',
              "exceptions": 'application/vnd.ogc.se_inimage',
            tilesOrigin: 116.14 + "," + 39.58
        }
      })
    });
    this.map.addLayer(this.pumpStationLayer);
  }

  addwaterGateLayer() {
    this.waterGateLayer =  new ol.layer.Tile({
      visible: false,
      source: new ol.source.TileWMS({
        url: 'http://39.101.128.113:8090/geoserver/provide_water/wms',
        params: {'FORMAT': 'image/png', 
                  'VERSION': '1.1.1',
                  tiled: true,
              "STYLES": '',
              "LAYERS": 'provide_water:waterGate',
              "exceptions": 'application/vnd.ogc.se_inimage',
            tilesOrigin: 116.1270651 + "," + 39.58
        }
      })
    });
    this.map.addLayer(this.waterGateLayer);
  }

  addHaiDXzqhLayer() {
    this.HaiDXzqhLayer = new ol.layer.Tile({
      visible: false,
      source: new ol.source.TileWMS({
        url: 'http://39.101.128.113:8090/geoserver/provide_water/wms',
        params: {'FORMAT': 'image/png', 
                  'VERSION': '1.1.1',
                  tiled: true,
              "STYLES": '',
              "LAYERS": 'provide_water:HaiDingQu',
              "exceptions": 'application/vnd.ogc.se_inimage',
            tilesOrigin: 116.04255177120073 + "," + 39.88536704424589
        }
      })
    });
    this.map.addLayer(this.HaiDXzqhLayer);
  }

  // 测试
  tileName = [
    {name: 'CuiHu', 'CuiHu': null, extent: [116.126149, 40.065886, 116.189128, 40.123656]},
    {name: 'DaoXiangHu', 'DaoXiangHu': null, extent: [116.144321, 40.030469, 116.229907, 40.11494]},
    {name: 'ShangZhuan', 'ShangZhuan': null, extent: [116.160255, 40.101576, 116.21268, 40.151244]},
    {name: 'YongFeng', 'YongFeng': null, extent: [116.214401, 40.04515, 116.263749, 40.11996]},
    {name: 'YuQuanShan', 'YuQuanShan': null, extent: [116.216499, 39.972252, 116.277876, 40.013892]},
    {name: 'YuanMingYuan', 'YuanMingYuan': null, extent: [116.293356, 39.975554, 116.322782, 40.030497]},
  ]
  addTile() {
    for(let i = 0, length = this.tileName.length; i < length; i++) {
      const name = this.tileName[i].name
      this.tileName[i][name] = new ol.layer.Tile({
        visible: true,
        source: new ol.source.TileWMS({
          url: 'http://39.101.128.113:8090/geoserver/provide_water/wms',
          params: {'FORMAT': 'image/png', 
                    'VERSION': '1.1.1',
                    tiled: true,
                "STYLES": '',
                "LAYERS": `provide_water:${name}`,
                "exceptions": 'application/vnd.ogc.se_inimage',
              tilesOrigin: 116.14432103787424 + "," + 40.040468712019916
          }
        })
      });
      this.map.addLayer(this.tileName[i][name]);
    }
  }

  setVisible(layerName="CuiHu") {
    if(layerName == 'All') {
      this.tileName.forEach(item => {
        item[item.name].setVisible(true);
        this.map.getView().fit([116.116149, 39.972252, 116.322782, 40.151244])
      })
    } else {
      this.tileName.forEach((item) => {
        if(item.name === layerName) {
          item[layerName].setVisible(true);
          this.map.getView().fit(item.extent)
        } else {
          item[item.name].setVisible(false);
        }
      })
    }
  }

  // 地图
  clearBaselayers() {
    for (let i = 0, length = this.baselayers.length; i < length; i++) {
      this.map.removeLayer(this.baselayers[i]);
    }
    this.baselayers = [];
  }

  setBaselayers() {
    for (let length = this.baselayers.length, i = length - 1; i >= 0 ; i--) {
      this.map.getLayers().insertAt(0, this.baselayers[i]);
    }
  }

  static Projection4326 = new ol.proj.Projection({
    code: 'EPSG:4326',
    extent: [-180, -90, 180, 90],  // global extent
    units: 'm',
    axisOrientation: 'neu'
  });

  CreateTiandituVecCLayers() {
    let projection = MapControl.Projection4326;
    let size = ol.extent.getWidth(projection.getExtent()) / 256;
    let resolutions = new Array(19);
    let matrixIds = new Array(19);
    for (let z = 1; z < 19; ++z) {
      resolutions[z] = size / Math.pow(2, z);
      matrixIds[z] = z;
    }
    return [
      new ol.layer.Tile({
        source: new ol.source.WMTS({
            url: 'http://t{0-7}.tianditu.com/vec_c/wmts?tk=2c2a1bc8a5853ce3ba6fe856d5600db6',
            layer: 'vec',
            matrixSet: 'c',
            format: 'tiles',
            projection: projection,
            tileGrid: new ol.tilegrid.WMTS({
                origin: ol.extent.getTopLeft(projection.getExtent()),
                resolutions: resolutions,
                matrixIds: matrixIds
            }),
            style: 'default',
            wrapX: true
        }),
        projection: projection,
        visible: true,
      }),
      // new ol.layer.Tile({
      //   source: new ol.source.WMTS({
      //       url: 'http://t{0-7}.tianditu.com/cva_c/wmts?tk=2c2a1bc8a5853ce3ba6fe856d5600db6',
      //       layer: 'cva',
      //       matrixSet: 'c',
      //       format: 'tiles',
      //       projection: projection,
      //       tileGrid: new ol.tilegrid.WMTS({
      //           origin: ol.extent.getTopLeft(projection.getExtent()),
      //           resolutions: resolutions,
      //           matrixIds: matrixIds
      //       }),
      //       style: 'default',
      //       wrapX: true,
      //   }),
      //   projection: projection,
      //   visible: true,
      // })
    ];
  }

}