<template>
  <div class="map-wrapper">
    <el-dialog
      :close-on-click-modal="false"
      title="自定义目标调度"
      :visible.sync="addVisible"
      width="830px"
      :before-close="closeAddEdit"
    >
      <div>
        <el-form
          size="mini"
          :rules="rules"
          ref="area"
          :model="addFormData"
          label-width="100px"
        >
          <el-form-item label="模型:" prop="modelId">
            <el-select
              v-model="addFormData.modelId"
              placeholder="请选择模型"
              clearable
              style="width: 70%"
            >
              <el-option
                v-for="item in modelList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="模型方案:" prop="modelId">
            <el-select
              v-model="addFormData.modelId"
              placeholder="请选择模型方案"
              clearable
              style="width: 70%"
            >
              <el-option
                v-for="item in modelList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <div
            style="display: flex; width: 100%; justify-content: space-between"
          >
            <div style="width: 74%">
              <div
                style="display: flex; position: relative"
                v-for="(item, index) in addFormData.list"
                :key="index"
              >
                <el-form-item label="河道:" prop="modelId">
                  <el-select
                    v-model="item.riverName"
                    placeholder="请选择河道"
                    clearable
                  >
                    <el-option
                      v-for="item in modelList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="目标水位(m):" prop="modelId">
                  <el-input-number
                    v-model="item.firstNum"
                    :precision="2"
                    :step="0.1"
                    :max="10"
                  ></el-input-number>
                </el-form-item>
                <span
                  style="
                    padding-left: 5px;
                    padding-right: 5px;
                    line-height: 30px;
                  "
                  >~</span
                >
                <el-form-item label-width="0px" prop="modelId">
                  <el-input-number
                    v-model="item.lastNum"
                    :precision="2"
                    :step="0.1"
                    :max="10"
                  ></el-input-number>
                </el-form-item>
                <img
                  @click="delect(index)"
                  v-if="addFormData.list.length > 1"
                  style="
                    position: absolute;
                    width: 16px;
                    top: -9px;
                    right: -17px;
                    z-index: 11;
                  "
                  src="@/assets/smartWater/hydratedCloudMap/close.png"
                />
              </div>
            </div>
            <div>
              <el-button
                style="background-color: #0e5694; border: none"
                type="primary"
                size="mini"
                @click="add"
                >添加</el-button
              >
              <el-button
                style="background-color: #0e5694; border: none"
                type="primary"
                size="mini"
                >生成补水方案</el-button
              >
            </div>
          </div>
        </el-form>
        <div
          style="
            width: 100%;
            text-align: center;
            margin-bottom: 10px;
            font-weight: bold;
          "
        >
          补水方案
        </div>
        <el-table
          :border="false"
          :data="tableData2"
          show-summary
          style="width: 100%; color: #ffffff"
          :header-cell-style="{
            textAlign: 'center',
            backgroundColor: '#2074B8',
            border: 'none',
            color: '#ffffff',
          }"
          :cell-style="{
            textAlign: 'center',
            backgroundColor: '#0E5694',
          }"
        >
          <el-table-column type="index" label="序号"> </el-table-column>
          <el-table-column prop="name" label="补水河渠(名称)">
          </el-table-column>
          <el-table-column prop="num" label="日补水量(万m³)"> </el-table-column>
          <el-table-column prop="status" label="实时补水量(m³)">
          </el-table-column>
          <el-table-column prop="snum" label="目标水位"> </el-table-column>
          <el-table-column prop="rnum" label="水位上限"> </el-table-column>
        </el-table>
        <div class="dialog_content">水位达标率: 93%</div>
        <div class="dialog_content">理论功耗(14天): 30582kwh</div>
        <div class="dialog_content">水质达标率: 100%</div>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="closeAddEdit"
          >取消</el-button
        >

        <el-button size="small" type="primary" @click="save">保存</el-button>
      </span> -->
    </el-dialog>
    <el-dialog
      title="模型介绍"
      :visible.sync="dialogVisible"
      width="40%"
      backgroundColor="transparent"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <div style="line-height: 20px">
        <div>范围:</div>
        <div>
          海淀区循环水网建设工程分南北两地6大片区实施:
          南部地区主要围绕“三山五园”地区,分别以金河、万泉河为轴,建设玉泉山片区、圆明园片区、颐和园片区3大循环水网,恢复河湖水景观、水生态,打造亲水宜人的文化走廊。
          北部地区主要以南沙河为轴线,建设翠湖片区、稻香湖片区、永丰片区3大循环水网,逐步形成湿地相连、河渠互通、各功能互补的水系生态系统,营造一碧万顷的海淀水生态。
        </div>
        <div>总体目标:</div>
        <div>
          完成玉泉山、圆明园、永丰等片区模型建设,进一步实现闸泵多目标优化调控功能,实现多方案智能决策和再生水水厂出水水量预测等模型功能。
          根据天气、降雨、污染、景观等情况,联合河道水量、水质设备和水量预测模型,判断执行调度指令,根据情况自动匹配片区内各河道水量,在满足生态基流的基础上实现最优供水.
        </div>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false" size="mini"
          >确 定</el-button
        >
      </span> -->
    </el-dialog>
    <div class="map-title">
      <div class="map-title_box">
        <!-- <span>海淀区生态补水循环水网控制系统</span> -->
        <img
          class="exit"
          title="退出"
          src="@/assets/smartWater/hydratedCloudMap/ext.png"
          @click="back"
        />
      </div>
    </div>
    <div class="map-top">
      <div class="map-top_box">
        <div
          class="map-top_box_item"
          v-for="(item, index) in imgList"
          :key="index"
          @click="selectMenu(index)"
          :class="{ left: index === 4 }"
        >
          <img v-if="selectIndex == index" :src="item.select" />
          <img v-else :src="item.url" />
        </div>
      </div>
    </div>
    <div class="abbreviate">
      <img src="@/assets/smartWater/hydratedCloudMap/ruhaixizha.png" />
      <span class="abbreviate_titlt" style="font-size: 12px">概览</span>
      <div class="abbreviate_content">总体补水情况</div>
      <div class="btn" v-if="!infoVisible" @click="openInfo">展开详情</div>
      <div class="btn" v-else @click="openInfo">收起详情</div>
    </div>
    <div class="dialog" v-show="infoVisible">
      <div class="dialog_top">
        <div class="dialog_title">总体补水情况</div>
        <div style="font-size: 24px; cursor: pointer" @click.prevent="openInfo">
          ×
        </div>
      </div>
      <el-table
        :border="false"
        :data="tableData"
        stripe
        style="width: 100%; margin-top: 1vh; color: #ffffff"
        :header-cell-style="{
          textAlign: 'center',
          backgroundColor: '#2074B8',
          border: 'none',
          color: '#ffffff',
        }"
        :cell-style="{
          textAlign: 'center',
        }"
      >
        <el-table-column prop="name" label="片区" width="70"> </el-table-column>
        <el-table-column prop="num" label="河道数量" width="70">
        </el-table-column>
        <el-table-column prop="status" label="补水状态" width="70">
        </el-table-column>
        <el-table-column prop="snum" label="实时补水量(m³/s)" width="70">
        </el-table-column>
        <el-table-column prop="rnum" label="日补水量(m³)" width="60">
        </el-table-column>
        <el-table-column prop="nnum" label="年补水量(m³)" width="60">
        </el-table-column>
        <el-table-column prop="power" label="理论日功耗(kwh)" width="70">
        </el-table-column>
      </el-table>
      <div class="dialog_title" style="margin-top: 2vh">总体设备情况</div>
      <div class="dialog_content">泵站: 6(个),1个开启</div>
      <div class="dialog_content">水闸: 15(个),6个开启</div>
      <div class="dialog_content">摄像头: 20(个)</div>
    </div>
    <div class="map-bottom" v-show="modelVisble">
      <img src="@/assets/smartWater/hydratedCloudMap/haidian.png" />
      <div class="btn" @click="openIntroduce">展开详情</div>
    </div>
    <div class="map-bottom" style="background-image: none" v-show="handVisible">
      <div
        class="map-bottom_box"
        v-for="(item, index) in btnList"
        :key="item.name"
        @click="selectbtn(index)"
        :style="{
          backgroundImage:
            'url(' + (sIndex == index ? item.select : item.url) + ')',
        }"
      >
        <!-- <img src="@/assets/smartWater/hydratedCloudMap/dispatch.png" /> -->
        <span>{{ item.name }}</span>
      </div>
    </div>
    <Map ref="map"></Map>
  </div>
</template>

<script>
import Map from "@/components/geom/map.vue";
export default {
  components: {
    Map,
  },
  data() {
    return {
      map: "",
      point: "",
      infoVisible: true,
      tableData: [
        {
          name: "翠湖片区",
          num: "4",
          status: "正在补水",
          snum: "4",
          rnum: "4",
          nnum: "4",
          power: "4",
        },
        {
          name: "翠湖片区",
          num: "4",
          status: "正在补水",
          snum: "4",
          rnum: "4",
          nnum: "4",
          power: "4",
        },
        {
          name: "翠湖片区",
          num: "4",
          status: "正在补水",
          snum: "4",
          rnum: "4",
          nnum: "4",
          power: "4",
        },
        {
          name: "翠湖片区",
          num: "4",
          status: "正在补水",
          snum: "4",
          rnum: "4",
          nnum: "4",
          power: "4",
        },
        {
          name: "翠湖片区",
          num: "4",
          status: "正在补水",
          snum: "4",
          rnum: "4",
          nnum: "4",
          power: "4",
        },
        {
          name: "翠湖片区",
          num: "4",
          status: "正在补水",
          snum: "4",
          rnum: "4",
          nnum: "4",
          power: "4",
        },
        {
          name: "翠湖片区",
          num: "4",
          status: "正在补水",
          snum: "4",
          rnum: "4",
          nnum: "4",
          power: "4",
        },
      ],
      tableData2: [
        {
          name: "后沙涧沟",
          num: "4",
          status: "5",
          snum: "4",
          rnum: "4",
        },
        {
          name: "后沙涧沟",
          num: "4",
          status: "5",
          snum: "4",
          rnum: "4",
        },
        {
          name: "后沙涧沟",
          num: "4",
          status: "5",
          snum: "4",
          rnum: "4",
        },
        {
          name: "后沙涧沟",
          num: "4",
          status: "5",
          snum: "4",
          rnum: "4",
        },
      ],
      imgList: [
        {
          url: require("@/assets/smartWater/hydratedCloudMap/gailan.png"),
          select: require("@/assets/smartWater/hydratedCloudMap/gailanselect.png"),
          visible: false,
          name: "概览",
        },
        {
          url: require("@/assets/smartWater/hydratedCloudMap/cuihu.png"),
          select: require("@/assets/smartWater/hydratedCloudMap/cuihuselect.png"),
          visible: false,
          name: "翠湖片区",
        },
        {
          url: require("@/assets/smartWater/hydratedCloudMap/daotian.png"),
          select: require("@/assets/smartWater/hydratedCloudMap/daotianselect.png"),
          visible: false,
          name: "稻香湖片区",
        },
        {
          url: require("@/assets/smartWater/hydratedCloudMap/yongfeng.png"),
          select: require("@/assets/smartWater/hydratedCloudMap/yongfengselect.png"),
          visible: false,
          name: "永丰片区",
        },
        {
          url: require("@/assets/smartWater/hydratedCloudMap/yuquanshan.png"),
          select: require("@/assets/smartWater/hydratedCloudMap/yuquanshanselect.png"),
          visible: false,
          name: "玉泉山片区",
        },
        {
          url: require("@/assets/smartWater/hydratedCloudMap/yuanmingyuan.png"),
          select: require("@/assets/smartWater/hydratedCloudMap/yuanmingyuanselect.png"),
          visible: false,
          name: "圆明园片区",
        },
        {
          url: require("@/assets/smartWater/hydratedCloudMap/yiheyuan.png"),
          select: require("@/assets/smartWater/hydratedCloudMap/yiheyuanselect.png"),
          visible: false,
          name: "颐和园片区",
        },
      ],
      btnList: [
        {
          url: require("../../assets/smartWater/hydratedCloudMap/btn.png"),
          select: require("../../assets/smartWater/hydratedCloudMap/btn2.png"),
          name: "调度方案",
        },
        {
          url: require("../../assets/smartWater/hydratedCloudMap/btn.png"),
          select: require("../../assets/smartWater/hydratedCloudMap/btn2.png"),
          name: "自定义目标调度",
        },
        {
          url: require("../../assets/smartWater/hydratedCloudMap/btn.png"),
          select: require("../../assets/smartWater/hydratedCloudMap/btn2.png"),
          name: "水位流量监测",
        },
        {
          url: require("../../assets/smartWater/hydratedCloudMap/btn.png"),
          select: require("../../assets/smartWater/hydratedCloudMap/btn2.png"),
          name: "水质监测",
        },
        {
          url: require("../../assets/smartWater/hydratedCloudMap/btn.png"),
          select: require("../../assets/smartWater/hydratedCloudMap/btn2.png"),
          name: "视频监控",
        },
      ],
      selectIndex: 0,
      dialogVisible: false,
      modelVisble: true,
      handVisible: false,
      addVisible: false,
      addFormData: {
        modelId: "",
        num: "",
        list: [
          {
            riverName: "",
            firstNum: "",
            lastNum: "",
          },
        ],
      }, //新增
      rules: {
        siteId: [
          {
            required: true,
            message: "请选择栏目",
            trigger: "change",
          },
        ],
      },
      modelList: [
        {
          name: "翠湖片区模型",
          id: 0,
        },
        {
          name: "颐和园片区模型",
          id: 1,
        },
      ],
      sIndex: 6,
    };
  },
  mounted() {
    // this.init();
  },
  methods: {
    init() {
      this.map = new BMapGL.Map("container");
      this.point = new BMapGL.Point(116.404, 39.915);
      this.map.centerAndZoom(this.point, 15);
      this.map.enableScrollWheelZoom(true);
      // this.map.setMapStyleV2({
      //   styleId: "d820ed90e4fcd50d6ec9ffd68c2ce78a",
      // });
    },
    openInfo() {
      this.infoVisible = !this.infoVisible;
    },
    selectMenu(index) {
      this.selectIndex = index;
      this.sIndex = 6;
      switch (this.imgList[index].name) {
        case "概览":
          this.handVisible = false;
          this.modelVisble = true;
          break;
        default:
          this.handVisible = true;
          this.modelVisble = false;
          break;
      }
      this.$refs.map.setVisible(this.imgList[index].name);
    },
    selectbtn(index) {
      this.sIndex = index;
      switch (this.btnList[index].name) {
        case "自定义目标调度":
          this.addVisible = true;
          break;
        default:
          // this.handVisible = true;
          // this.modelVisble = false;
          break;
      }
    },
    handleClose(done) {
      this.dialogVisible = false;
    },
    openIntroduce() {
      this.dialogVisible = true;
    },
    save() {},
    closeAddEdit() {
      this.addVisible = false;
    },
    add() {
      let obj = {
        riverName: "",
        firstNum: "",
        lastNum: "",
      };
      this.addFormData.list.push(obj);
    },
    delect(i) {
      this.addFormData.list.splice(i, 1);
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.map-wrapper {
  width: 100%;
  position: relative;
  .dialog_title {
    width: 100%;
    font-size: 14px;
  }
  .dialog_content {
    width: 100%;
    margin-top: 2vh;
    font-size: 12px;
  }
  .map-title {
    width: 100%;
    height: 10vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    // text-align: center;
    background: linear-gradient(
      to bottom,
      rgba(38, 104, 212, 0.7),
      rgba(108, 202, 252, 0)
    );
    // font-size: 32px;
    font-weight: bold;
    color: #ffffff;
    .map-title_box {
      // margin-top: 10vh;
      width: 100%;
      height: 100%;
      background-image: url("../../assets/smartWater/hydratedCloudMap/titback.png");
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: 0 0;
    }
    .exit {
      position: absolute;
      width: 1.5vw;
      // height: 2.3vh;
      right: 1vw;
      top: 1.3vh;
      z-index: 11;
    }
  }
  .map-top {
    z-index: 10;
    position: absolute;
    top: 4vh;
    left: 0;
    width: 100%;
    display: flex;
    // align-items: center;
    justify-content: space-between;
    img {
      width: 7.5vw;
      height: 4.8vh;
    }
    .map-top_box {
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 0 0.5vw;
      width: 31vw;
    }
  }
  .abbreviate {
    position: absolute;
    top: 21vh;
    left: 2vw;
    z-index: 10;
    font-size: 12px;
    // background-color: #fff;
    background-image: url(../../assets/smartWater/hydratedCloudMap/overview.png);
    background-repeat: no-repeat;
    background-size: 100% 130%;
    background-position: center;
    padding: 7px 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    color: #ffffff;
    img {
      width: 1vw;
      height: 2vh;
    }
    .abbreviate_titlt {
      margin-left: 8px;
      // font-weight: bold;
    }
    .abbreviate_content {
      font-size: 10px;
      margin-left: 12px;
      background-color: #196cac;
      padding: 6px 20px;
      // color: #ffffff;
      font-weight: bold;
    }
    .btn {
      padding: 6px 10px;
      background-color: transparent;
      font-size: 10px;
      margin-left: 16px;
      background-image: url(../../assets/smartWater/hydratedCloudMap/btn.png);
      background-repeat: no-repeat;
      background-size: 106% 134%;
      background-position: center;
      cursor: pointer;
    }
  }
  .dialog {
    // width: 38vw;
    position: absolute;
    top: 28vh;
    left: 2vw;
    z-index: 10;
    background-image: url(../../assets/smartWater/hydratedCloudMap/dialog.png);
    background-repeat: no-repeat;
    background-size: 102% 104%;
    background-position: center;
    // background-color: #ffffff;
    padding: 10px 10px 15px 10px;
    border-radius: 10px;
    color: #ffffff;
    .dialog_top {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .map-bottom {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 3vh;
    z-index: 10;
    background-image: url(../../assets/smartWater/hydratedCloudMap/overview.png);
    background-repeat: no-repeat;
    // background-size: 100%;
    background-position: center;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 6px 20px;
    color: #ffffff;
    img {
      width: 3vw;
    }
    .btn {
      padding: 6px 10px;
      background-color: transparent;
      font-size: 10px;
      margin-left: 20px;
      background-image: url(../../assets/smartWater/hydratedCloudMap/btn2.png);
      background-repeat: no-repeat;
      background-size: 106% 134%;
      background-position: center;
      cursor: pointer;
    }
    .map-bottom_box {
      // background-color: #ffffff;
      display: flex;
      align-items: center;
      padding: 8px 8px;
      font-size: 12px;
      margin-left: 8px;
      // box-shadow: 0px 0px 5px 1px #bcbcbd;
      cursor: pointer;
      // background-image: url(../../assets/smartWater/hydratedCloudMap/btn.png);
      background-repeat: no-repeat;
      background-size: 106% 134%;
      background-position: center;
      span {
        margin-left: 6px;
        color: #ffffff;
      }
    }
  }
  #container {
    width: 100%;
    height: 100vh;
  }
  /deep/.el-table__row > td {
    border: none;
  }
  .el-table {
    font-size: 12px;
    background-color: #2074b8;
  }
  .el-table::before {
    height: 0;
  }
  /deep/.el-table .el-table__cell {
    padding: 2px 0 !important;
    text-align: center;
    // background-color: #fffee0;
    border: none;
  }
  /deep/.el-form-item__label {
    color: #ffffff;
  }
  /deep/.el-input--mini .el-input__inner {
    background-color: #155f99;
    border: none;
    color: #ffffff;
  }
  /deep/ .el-dialog__body {
    padding: 20px;
    color: #ffffff !important;
  }
  /deep/.el-dialog__close {
    color: #ffffff !important;
  }
  /deep/.el-dialog__title {
    color: #ffffff;
  }
  /deep/ .el-dialog {
    background-color: transparent;
    background-image: url(../../assets/smartWater/hydratedCloudMap/dialog.png);
    background-repeat: no-repeat;
    background-size: 102% 102%;
    background-position: center;
  }
  // /deep/ .el-input-number__decrease {
  //   background-color: #196BAB;
  //   color: #ffffff;
  // }
  // /deep/ .el-input-number__increase {
  //   background-color: #196BAB;
  //   color: #ffffff
  // }
  .left {
    margin-left: 39vw;
    margin-top: 0.3vh;
  }
  /deep/ .el-input-number__decrease,
  /deep/.el-input-number__increase {
    width: 24px;
    background-color: #196bab;
    color: #ffffff;
    border: none;
  }
  .el-input-number--mini {
    width: 115px;
  }
  /deep/.el-table tr {
    background-color: #0e5694;
  }
  /deep/ .el-table__row--striped td.el-table__cell {
    background-color: #1967a2 !important;
  }
  /deep/ .el-table__cell {
    background-color: #0e5694;
    color: #ffffff;
  }
  // /deep/.el-table__body tr.hover-row.current-row > td.el-table__cell,
  // .el-table__body
  //   tr.hover-row.el-table__row--striped.current-row
  //   > td.el-table__cell,
  // .el-table__body tr.hover-row.el-table__row--striped > td.el-table__cell,
  // .el-table__body tr.hover-row > td.el-table__cell {
  //   background-color: #0e5694;
  // }
  /deep/.el-table--enable-row-hover
    .el-table__body
    tr:hover
    > td.el-table__cell {
    background-color: #0e5694;
  }
  
}
</style>