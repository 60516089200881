<template>
  <div ref="rootDiv" style="height: 100vh; width: 100%">
    <div
      v-if="mapControl && mapControl.divId"
      :id="mapControl.divId"
      style="width: 100%; height: 100%"
    ></div>
    <slot></slot>
  </div>
</template>

<script>
import { MapControl } from "./map-control.js";
export default {
  data() {
    return {
      rootDiv: null,
      mapControl: null,
    };
  },
  props: {
    name: {
      type: String,
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    randomId() {
      return (Math.random() + "").substring(2);
    },
    init() {
      this.mapControl = new MapControl();
      // mapControl.vm = proxy;
      this.mapControl.name = this.name;
      this.mapControl.divId = "map:single";
      this.$nextTick(() => {
        this.mapControl.init();
      });
    },
  },
};
</script>

<style>
</style>