<template>
  <div class="legendview-layout">
    <el-row type="flex" justify="center" style="margin-top: 3px">
      <el-col :span="6">图例</el-col>
    </el-row>
    <el-row>
      <el-col :span="24" style="font-size:15px; margin-top: 3px; margin-left: 5px">设施信息</el-col>
    </el-row>
    <el-row style="margin-bottom: 2px">
      <el-col :span="6"><el-checkbox v-model="waterstationChecked" @change="waterstationChange" style="margin-left: 10px"></el-checkbox></el-col>
      <el-col :span="14" style="font-size:13px">水位站</el-col>
      <el-col :span="4" style="padding-top: 5px"><div class="ciclie-y"></div></el-col>
    </el-row>
    <el-row style="margin-bottom: 2px">
      <el-col :span="6"><el-checkbox v-model="waterqualityStationChecked" @change="waterqualityStationChange" style="margin-left: 10px"></el-checkbox></el-col>
      <el-col :span="14" style="font-size:13px">水质站</el-col>
      <el-col :span="4" style="padding-top: 5px"><div class="ciclie-p"></div></el-col>
    </el-row>
    <el-row style="margin-bottom: 2px">
      <el-col :span="6"><el-checkbox v-model="videoMonitorChecked" @change="videoMonitorChange" style="margin-left: 10px"></el-checkbox></el-col>
      <el-col :span="14" style="font-size:13px">视频监控点</el-col>
      <el-col :span="4" style="padding-top: 5px"><div class="ciclie-g"></div></el-col>
    </el-row>
    <el-row style="margin-bottom: 2px">
      <el-col :span="6"><el-checkbox v-model="waterworksChecked" @change="waterworksChange" style="margin-left: 10px"></el-checkbox></el-col>
      <el-col :span="14" style="font-size:13px">再生水厂</el-col>
      <el-col :span="4" style="padding-top: 5px"><div class="ciclie-b"></div></el-col>
    </el-row>
    <el-row style="margin-bottom: 2px">
      <el-col :span="6"><el-checkbox v-model="drainChecked" @change="drainChange" style="margin-left: 10px"></el-checkbox></el-col>
      <el-col :span="14" style="font-size:13px">排水口</el-col>
      <el-col :span="4" style="padding-top: 5px"><div class="ciclie-green"></div></el-col>
    </el-row>
    <el-row style="margin-bottom: 2px">
      <el-col :span="6"><el-checkbox v-model="pumpStationChecked" @change="pumpStationChange" style="margin-left: 10px"></el-checkbox></el-col>
      <el-col :span="14" style="font-size:13px">水泵站</el-col>
      <el-col :span="4" style="padding-top: 5px"><div class="ciclie-red"></div></el-col>
    </el-row>
    <el-row style="margin-bottom: 2px">
      <el-col :span="6"><el-checkbox v-model="waterGateChecked" @change="waterGateChange" style="margin-left: 10px"></el-checkbox></el-col>
      <el-col :span="14" style="font-size:13px">水闸</el-col>
      <el-col :span="4" style="padding-top: 5px"><div class="ciclie-shuiza"></div></el-col>
    </el-row>

    <el-row>
      <el-col :span="24" style="font-size:15px; margin-top: 3px; margin-left: 5px">行政区</el-col>
    </el-row>
    <el-row style="margin-bottom: 5px">
      <el-col :span="6"><el-checkbox v-model="HaiDXzqhChecked" @change="haiDChange" style="margin-left: 10px"></el-checkbox></el-col>
      <el-col :span="14" style="font-size:13px">海淀区图层</el-col>
      <el-col :span="4" style="padding-top: 5px"><div class="squre-red"></div></el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'legend-view',
  computed: {
    mapControl() {
      return this.$parent.mapControl;
    }
  },
  data () {
    return {
      waterstationChecked: false,
      waterqualityStationChecked: false,
      videoMonitorChecked: false,
      waterworksChecked: false,
      drainChecked: false,
      pumpStationChecked: false,
      waterGateChecked:false,
      HaiDXzqhChecked: false,
    }
  },
  methods: {
    waterstationChange(val) {
      this.mapControl.waterstationLayer.setVisible(val);
    },
    waterqualityStationChange(val) {
      this.mapControl.waterqualityStationLayer.setVisible(val);
    },
    videoMonitorChange(val) {
      this.mapControl.videoMonitorLayer.setVisible(val);
    },
    waterworksChange(val) {
      this.mapControl.waterworksLayer.setVisible(val);
    },
    drainChange(val) {
      this.mapControl.drainLayer.setVisible(val);
    },
    haiDChange(val) {
      this.mapControl.HaiDXzqhLayer.setVisible(val);
    },
    pumpStationChange(val) {
      this.mapControl.pumpStationLayer.setVisible(val);
    },
    waterGateChange(val) {
      this.mapControl.waterGateLayer.setVisible(val);
    }
  }
}
</script>

<style scoped>
.legendview-layout {
  position: absolute;
  width: 150px;
  background-color: rgba(32, 116, 184, 0.7);
  bottom: 10px;
  right: 10px;
  color: white;
}
.title {
  margin: 0 auto;
}

.ciclie-y {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #e5f810;
}

.ciclie-p {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #8820bd;
}

.ciclie-g {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #843f44;
}

.ciclie-b {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #23a8f2;
}

.ciclie-green {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #5bed7e;
}

.ciclie-red {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #e74032;
}

.ciclie-shuiza {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #498085;
}

.squre-red {
  width: 10px;
  height: 10px;
  border-bottom: 3px solid red;
}
</style>