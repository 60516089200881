<template>
  <div style="height: 100%; width: 100%">
    <map-container ref="mapContainer" name="containtMap">
      <legend-view></legend-view>
    </map-container>
  </div>
</template>

<script>
import MapContainer from "./map-container.vue";
import LegendView from "./legend-view.vue"
export default {
  components: {
    MapContainer,
    LegendView,
  },
  data() {
    return {
      nameList: {
        "概览": "All",
        "翠湖片区": "CuiHu",
        "稻香湖片区": "DaoXiangHu",
        "永丰片区": "YongFeng",
        "玉泉山片区": "YuQuanShan",
        "圆明园片区": "YuanMingYuan",
        "颐和园片区": "ShangZhuan"
      }
    }
  },
  methods: {
    setVisible(name) {
      const visibleName = this.nameList[name];
      this.$refs.mapContainer.mapControl.setVisible(visibleName);
    }
  }
};
</script>

<style>
</style>